import { useTranslation } from 'react-i18next'
import { Empty, Flex, List } from 'antd'

import { EquipmentCard } from '../atoms'

interface BlockListProps {
  title: string
  data: MachineDataProps[]
}

export const EquipmentVerticalList = ({ title, data }: BlockListProps) => {
  const { t } = useTranslation()
  return (
    <Flex vertical align="center" className="flex-1">
      <p className="text-lg">{title}</p>
      <div className="mt-2 h-1 w-16 bg-gray-300" />
      <List
        itemLayout="vertical"
        size="large"
        dataSource={data}
        split={false}
        className="scrollbar-hide h-full w-full overflow-y-auto pb-4"
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              style={{ width: 'calc(16.5vw)' }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('NoData')}
            />
          ),
        }}
        renderItem={(item) => <EquipmentCard data={item} />}
      />
    </Flex>
  )
}
