import { createTrackedSelector } from 'react-tracked'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface SettingState {
  theme: 'light' | 'dark'
  toggleTheme: () => void
}

const SettingStore = create<SettingState>()(
  persist(
    devtools(
      (set) => ({
        theme: 'light',
        toggleTheme: () => {
          set((state) => ({
            theme: state.theme === 'light' ? 'dark' : 'light',
          }))
        },
      }),
      { name: 'setting store', serialize: { options: true } },
    ),
    {
      name: 'setting_storage',
      getStorage: () => window.localStorage,
    },
  ),
)

export const useSettingStore = createTrackedSelector(SettingStore)
