import { PropsWithChildren, useEffect } from 'react'
import {
  AiOutlineBell,
  AiOutlineMoon,
  AiOutlineSetting,
  AiOutlineSun,
} from 'react-icons/ai'
import { Avatar, Badge, Layout } from 'antd'

import logo from '@/assets/img/logo.png'
import logoDark from '@/assets/img/logo-dark.png'
import userImg from '@/assets/img/user.png'
import { useAuth, useColor, useNavigation, useSettingStore } from '@/hooks'

import { LeftDrawer } from '../templates'
const { Header, Content } = Layout

export const ContentLayout = ({ children }: PropsWithChildren) => {
  const navigation = useNavigation()
  const { theme, toggleTheme } = useSettingStore()
  const colors = useColor()
  // const { isAuthenticated } = useAuth()

  // useEffect(() => {
  //   if (!isAuthenticated && navigation.currentPath !== '/login') {
  //     navigation.goTo('login', { state: { prevPage: navigation.currentPath } })
  //   }
  // }, [isAuthenticated])

  return (
    <Layout className="h-screen">
      <LeftDrawer />
      <Layout>
        <Header
          className="flex flex-row items-center justify-between px-4"
          style={{ backgroundColor: colors.background, color: colors.text }}
        >
          <div className="flex">
            <img
              src={theme === 'light' ? logo : logoDark}
              alt="logo"
              className="h-10"
            />
          </div>
          <div className="flex flex-row items-center gap-4">
            {theme === 'dark' ? (
              <AiOutlineSun
                size={24}
                className="cursor-pointer"
                color={colors.text}
                onClick={toggleTheme}
              />
            ) : (
              <AiOutlineMoon
                size={24}
                className="cursor-pointer"
                color={colors.text}
                onClick={toggleTheme}
              />
            )}
            <Badge dot>
              <AiOutlineBell
                size={24}
                className="cursor-pointer"
                color={colors.text}
              />
            </Badge>
            <AiOutlineSetting
              size={24}
              className="cursor-pointer"
              color={colors.text}
            />
            <Avatar size="large" src={userImg} className="cursor-pointer" />
          </div>
          {/* <Button type="primary" title={t('Logout')} onClick={logout}>
            {t('Logout')}
          </Button> */}
        </Header>
        <Content
          className="p-2"
          style={{ backgroundColor: colors.background, color: colors.text }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}
