import { lazy, Suspense, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Spin, Switch } from 'antd'
import axios from 'axios'

import { SENSOR_API } from '@/constants'

import { EquipmentVerticalList } from '../molecules'
import { ChemicalTable } from '../organisms'

const EmbeddedLabModel = lazy(() => import('../organisms/EmbeddedLabModel'))

type HomeTabs = 'chemical' | 'equipment' | 'layer' | 'pha'
const TABS: HomeTabs[] = ['chemical', 'equipment', 'layer', 'pha']
const EQUIP_LIST: EquipmentTypes[] = [
  'heat_exchanger',
  'vessels',
  'heater',
  'pumps',
  'pipeline',
  'gate_valve',
  'tank',
]

const defaultData: MachineDataProps[] = [
  {
    id: '1',
    name: 'E-1126',
    type: '管殼式熱交換器',
    connect: true,
    status: 1,
    description: 'UPPER CIRCULATING REFLUX / DESALTED CRUDE',
    temperature: 30,
  },
  {
    id: '4',
    name: 'E-1147',
    type: '管殼式熱交換器',
    connect: false,
    status: 2,
    description: 'LGO / COOLING WATER',
    humidity: 40,
    temperature: 30,
    image: 'https://placehold.co/70x100',
  },
  {
    id: '2',
    name: 'E-1150D',
    type: '管殼式熱交換器',
    connect: false,
    status: 3,
    description: 'ATMOSPHERIC RESIDUE / COOLING WATER',
    temperature: 30,
    image: 'https://placehold.co/70x100',
  },
  {
    id: '3',
    name: 'E-1131B',
    type: '管殼式熱交換器',
    connect: true,
    status: 1,
    description: 'ATMOSPHERIC RESIDUE / DESALTED CRUDE',
    humidity: 40,
    image: 'https://placehold.co/70x100',
  },
  {
    id: '5',
    name: 'E-1125',
    type: '管殼式熱交換器',
    connect: true,
    status: 1,
    description: 'ATMOSPHERIC RESIDUE / COOLING WATER',
    humidity: 40,
    image: 'https://placehold.co/70x100',
  },
]

export const HomePage = () => {
  const { t } = useTranslation()
  const [tab, setTab] = useState<HomeTabs>('equipment')
  const [modelVisible, setModelVisible] = useState(false)
  const [typedData, setTypedData] = useState<EquipmentData>({
    heat_exchanger: defaultData,
  } as EquipmentData)

  // useEffect(() => {
  //   if (!SENSOR_API) return
  //   axios
  //     .get(SENSOR_API)
  //     .then(({ data }) => {
  //       setTypedData({
  //         heat_exchanger: Object.keys(data).map((key) => ({
  //           ...data[key],
  //           name: key,
  //           connect: true,
  //         })),
  //         vessels: [],
  //         heater: [],
  //         pumps: [],
  //         pipeline: [],
  //         gate_valve: [],
  //         tank: [],
  //       })
  //     })
  //     .catch(console.error)
  // }, [SENSOR_API])

  const renderTabs = useCallback(
    (tab: HomeTabs) => {
      switch (tab) {
        case 'chemical':
          return <ChemicalTable data={[]} />
        case 'equipment':
          return EQUIP_LIST.map((block: EquipmentTypes) => (
            <EquipmentVerticalList
              key={block}
              data={typedData[block] || []}
              title={`${t(`Equipment.${block}`, block)}(${
                typedData[block]?.length || 0
              })`}
            />
          ))
        case 'layer':
          return <ChemicalTable data={[]} />
        case 'pha':
          return <ChemicalTable data={[]} />
        default:
          return null
      }
    },
    [typedData, tab],
  )

  return (
    <Flex vertical gap={24} className="scrollbar-hide h-full">
      {/* header */}
      <div className="px-8">
        {/* top */}
        <Flex vertical gap={10}>
          <Flex vertical>
            <span className="text-[#b4b5b0]">EC111A</span>
            <Flex align="center" gap={16}>
              <p className="text-xl font-bold">功能性高分子實驗室</p>
              <Flex gap={6}>
                <span style={{ lineHeight: '1.3rem' }}>3D Lab model</span>
                <Switch value={modelVisible} onChange={setModelVisible} />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {/* bottom */}
        <Flex className="mt-4" gap={10}>
          {TABS.map((type) => (
            <Button
              type={tab === type ? 'primary' : 'default'}
              shape="round"
              key={type}
              onClick={() => setTab(type)}
            >
              {t(`HomeTabs.${type}`, type)}
            </Button>
          ))}
        </Flex>
      </div>
      {/* list */}
      <Flex flex={1} className="h-full overflow-x-auto overflow-y-hidden">
        {modelVisible ? (
          <Suspense fallback={<Spin size="large" />}>
            <EmbeddedLabModel />
          </Suspense>
        ) : (
          renderTabs(tab)
        )}
      </Flex>
    </Flex>
  )
}
