import { useTranslation } from 'react-i18next'
import { CiWifiOff, CiWifiOn } from 'react-icons/ci'
import { Flex, Image, List } from 'antd'

interface StatusCardProps {
  data: MachineDataProps
}

const STATUS_COLOR = {
  1: '#2de479',
  2: '#858a96',
  3: '#efa83a',
}

const BORDER_COLOR: Record<string, string> = {
  3: 'red',
  2: 'orange',
  1: 'white',
}

export const EquipmentCard = ({ data }: StatusCardProps) => {
  const { t } = useTranslation()
  return (
    <List.Item key={data.id} style={{ paddingTop: 14, paddingBottom: 4 }}>
      <Flex
        className="rounded-3xl p-4"
        vertical={true}
        style={{
          backgroundColor: '#fff',
          borderWidth: 3,
          borderStyle: 'solid',
          borderColor: BORDER_COLOR[data.status],
          minHeight: '7.5vw',
          width: '16.5vw',
        }}
      >
        {/* name, status, connected */}
        <Flex align="center" gap={4}>
          {data.connect ? (
            <CiWifiOn size={28} color="#278edf" />
          ) : (
            <CiWifiOff size={28} color="#df353b" />
          )}
          <p className="text-xl font-bold text-[#45454b]">{data.name}</p>
          <div
            className="ml-auto h-4 w-4 rounded-full"
            style={{ backgroundColor: STATUS_COLOR[data.status ?? 1] }}
          />
        </Flex>
        {/* description */}
        <Flex className="mt-1">
          <span
            className="text-sm font-bold"
            style={{ color: data.type === 'Substrate' ? 'white' : '#b4b9bb' }}
          >
            {data.description}
          </span>
        </Flex>
        {/* humidity, temperature, remarks, image */}
        <Flex justify="space-between" align="flex-end" flex={1}>
          <Flex vertical>
            {data.remarks && (
              <div className="rounded-full bg-[#f0f9fb] px-[5px]">
                <span className="text-[#1d7395]">{data.remarks}</span>
              </div>
            )}
            <Flex gap={4} className="mt-2">
              {data.humidity! > 0 && (
                <div className="rounded-full bg-[#f6cf8a] px-[8px] py-[2px]">
                  <span className="text-xs text-[#996920]">
                    {t('Humidity')}
                    {data.humidity}%
                  </span>
                </div>
              )}
              {data.temperature! > 0 && (
                <div className="rounded-full bg-[#fef8ec] px-[8px] py-[2px]">
                  <span className="text-xs text-[#9e722d]">
                    {t('Temperature')}
                    {data.temperature}°C
                  </span>
                </div>
              )}
            </Flex>
          </Flex>
          {data.image && <Image src={data.image} preview={false} />}
        </Flex>
      </Flex>
    </List.Item>
  )
}
