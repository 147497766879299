export const Colors = {
  light: {
    primary: '#0e6195',
    background: '#eff0e9',
    text: '#000',
    title: '#1a2551',
    card: '#2f2f2f',
  },
  dark: {
    primary: '#0e6195',
    background: '#171c1f',
    text: '#fff',
    title: '#ddd',
    card: '#2f2f2f',
  },
}
