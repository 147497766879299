import { Fragment, PropsWithChildren, Suspense, useEffect } from 'react'
import {
  createRoutesFromChildren,
  HashRouter,
  matchRoutes,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider, theme } from 'antd'

import { ContentLayout, DefaultLayout, NoLayout } from '@/components/layouts'

import { SENTRY_DSN, SENTRY_ENV, SENTRY_RELEASE } from '@/constants'

import { useSettingStore } from './hooks'
import { ROOT_ROUTES } from './route'
import RedirectRoute from './routes/index'

const { darkAlgorithm, defaultAlgorithm } = theme

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  environment: SENTRY_ENV || 'development',
  release: SENTRY_RELEASE,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const APP_LAYOUTS = {
  default: DefaultLayout,
  content: ContentLayout,
  none: NoLayout,
} as const
export type AppLayout = keyof typeof APP_LAYOUTS

const queryClient = new QueryClient()
const QueryProvider = ({ children }: PropsWithChildren) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

const LayoutComponent = ({
  layout = 'default',
  children,
}: PropsWithChildren<{ layout: AppLayout }>) => {
  const APPLayout = APP_LAYOUTS[layout]
  return <APPLayout>{children}</APPLayout>
}
const RouteContent = () => {
  return (
    <HashRouter>
      <SentryRoutes>
        {ROOT_ROUTES.map(({ path, component: Component = Fragment }) => (
          <Route
            key={path}
            path={path}
            element={
              <LayoutComponent layout={Component.layouts}>
                <Component />
              </LayoutComponent>
            }
          />
        ))}
        {/* default route */}
        <Route path="*" element={<RedirectRoute />} />
      </SentryRoutes>
    </HashRouter>
  )
}

const AppContent = () => {
  return (
    <QueryProvider>
      <Suspense fallback={'Loading...'}>
        <RouteContent />
      </Suspense>
    </QueryProvider>
  )
}

function App() {
  const { theme } = useSettingStore()
  return (
    <ConfigProvider
      theme={{
        algorithm: theme === 'dark' ? darkAlgorithm : defaultAlgorithm,
      }}
    >
      <AppContent />
    </ConfigProvider>
  )
}

export default App
